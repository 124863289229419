// noinspection GraphQLUnresolvedReference

import gql from 'graphql-tag';
import FORM_OPERATION_BODY from '@/graphql/shared/form-operation-body';

const MUTATION = gql`
  mutation UpdateForgottenPassword($input: NewPasswordWithCodeInput!) {
    passwordForgotten: updateForgottenPassword(input: $input) {
      status
      message
    }
  }
`;

const QUERY = gql`
  query Form($token: String!) {
    form: updateForgottenPasswordForm(token: $token) {
      ${FORM_OPERATION_BODY}
    }
  }
`;

export { QUERY, MUTATION };
